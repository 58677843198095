import React from 'react'
import { useNavigate } from "react-router";

const Cardinfo = () => {
  const navigate = useNavigate()
  return (
    <div className='pb-10'>
      <div className='pb-3 mt-5 border border-booking-primaryBorder rounded-booking-lg'>
        <div className='p-3 font-normal text-booking-primary font-booking-secondary text-booking-2xl'>Kreditkarteninformation</div>
        <div className='border-b border-booking-primary-400'></div>
        <div className='p-5'>
          <div className='flex gap-5 pt-5'>
            <div className='relative w-1/2'>
              <span className='absolute z-10 top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5'>Kreditkartennummer</span>
              <div><input type="text" name="MwSt" id="MwSt" className='w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg' placeholder='Kreditkartennummer' /></div>
            </div>
            <div className='flex w-1/2'>
              <span><img src="%PUBLIC_URL%/img/visa.svg" alt="visa_logo" /></span>
              <span><img src="%PUBLIC_URL%/img/masterCard.svg" alt="mastercard_logo" className='pt-1' /></span>
            </div>
          </div>
          <div className='flex gap-5 pt-5'>
            <div className='relative w-1/2'>
              <span className='absolute z-10 top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5'>Ablaufdatum</span>
              <div><input type="text" name="MwSt" id="MwSt" className='w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg' placeholder='MM/YY' /></div>
            </div>
            <div className='relative w-1/2'>
              <span className='absolute z-10 top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5'>CVV</span>
              <div><input type="text" name="MwSt" id="MwSt" className='w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg' placeholder='CVV' /></div>
            </div>
          </div>
          <div className='flex gap-5 pt-5'>
            <div className='relative w-full'>
              <span className='absolute z-10 top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5'>Name des Karteninhabers</span>
              <div><input type="text" name="MwSt" id="MwSt" className='w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg' placeholder='Name des Karteninhabers' /></div>
            </div>
          </div>
        </div>

      </div>
      <div className='mt-8'>
        <div className='flex my-3 custom-checkbox'>
          <input type="checkbox" name="gt/agb" id="gt/agb" />
          <span className="checkmark"></span>
          <label className='font-normal text-booking-primary font-booking-primary text-booking-lg' htmlFor="gt/agb">GT/AGB</label>
        </div>

        <div className='flex my-3 custom-checkbox'>
          <input type="checkbox" name="gt/agb" id="gt/agb1" />
          <span className="checkmark"></span>
          <label className='font-normal text-booking-primary font-booking-primary text-booking-lg' htmlFor="gt/agb1">Privatsphäre</label>
        </div>

        <div className='flex my-3 custom-checkbox'>
          <input type="checkbox" name="gt/agb" id="gt/agb2" />
          <span className="checkmark"></span>
          <label className='font-normal text-booking-primary font-booking-primary text-booking-lg' htmlFor="gt/agb2">Versicherung</label>
        </div>
      </div>

      <div className='flex justify-end'>
        <button onClick={() => navigate("/bookingsuccess")} className='px-4 py-2 font-semibold capitalize rounded-full text-booking-tertiary-300 font-booking-secondary text-booking-lg bg-booking-secondary'>Jetzt bezahlen</button>
      </div>
    </div>
  )
}

export default Cardinfo

import React from 'react'
import FilterCheckbox from './FilterCheckbox.jsx'

const HotelFilters = () => {
  let features = [
    'Allgemein',
    'Wellnessbereich',
    'Winter',
    'Sport',
    'öffentliche Bereiche',
    'Anbindungen',
    'Ortschaft',
    'Bademöglichkeit',
    'Sommer',
    'Gastronomie',
  ]
  let Catering = ['Keine Verpflegung', 'Fruhstuck', 'Halbpension', 'All inclusive']

  let roomtype = [
    'Doppelzimmer',
    'Familienzimmer',
    'Apartment / Bunglow',
    'Superior / Deluxe',
    'Einzelzimmer',
    'Suite / Junior Suite',
  ]

  return (
    <div className="pr-5">
      <div className="px-5 border border-booking-primaryBorder rounded-booking-lg py-7">
        <h6>Hotelkategorie ab</h6>
        <span className="flex pt-3">
          <img src="%PUBLIC_URL%/img/icon/starbluefill.svg" alt="star1" />
          <img src="%PUBLIC_URL%/img/icon/starbluefill.svg" alt="star2" />
          <img src="%PUBLIC_URL%/img/icon/starbluefill.svg" alt="star3" />
          <img src="%PUBLIC_URL%/img/icon/starblueunfill.svg" alt="star4" />
          <img src="%PUBLIC_URL%/img/icon/starblueunfill.svg" alt="star5" />
        </span>
      </div>

      <div className="px-5 mt-5 border border-booking-primaryBorder rounded-booking-lg py-7">
        <h6>Hotelmerkmale</h6>
        <hr className="h-px my-2.5 border-booking-primaryBorder" />
        <FilterCheckbox props={features} name="features" />
      </div>

      <div className="py-5 mt-5 border border-booking-primaryBorder rounded-booking-lg px-7">
        <h6>Verpflegung</h6>
        <hr className="h-px my-2.5" />
        <FilterCheckbox props={Catering} name="Catering" />
      </div>
      <div className="py-5 mt-5 border border-booking-primaryBorder rounded-booking-lg px-7">
        <h6>Zimmertyp</h6>
        <hr className="h-px my-2.5" />
        <FilterCheckbox props={roomtype} name="roomtype" />
        <hr className="mt-3" />
        <div className="flex mt-4 custom-checkbox">
          {' '}
          <input className="pe-3" id="Meerblick" type="checkbox" />
          <span className="checkmark"></span>
          <label htmlFor="Meerblick" className="cursor-pointer text-booking-tertiary">
            mit Meerblick
          </label>
        </div>
      </div>
    </div>
  )
}

export default HotelFilters

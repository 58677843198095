import React, { FC, Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { formatPrice } from '../../../utils/reusableFunctions'
import { SummaryDetails } from '../../'

const RoomDetails = ({
  products,
  rooms,
  adults,
  arrival,
  departure,
  nights,
  productDetails,
  selectedRooms,
  setSelectedRooms,
  totalPrice,
  setTotalPrice,
  totalGuests,
  setTotalGuests,
  totalRooms,
  setTotalRooms,
}) => {
  // const productDetails = products.find((product) => product?.productCode === productCode)
  const filteredooms = rooms?.filter((room) => room?.productId === productDetails?.productCode)
  const navigate = useNavigate()

  const [showImage, setShowImage] = useState(false)
  const [showImage2, setShowImage2] = useState(false)
  const [accordion, setAccordion] = useState({})

  // const [selectedRooms, setSelectedRooms] = useState({
  //   rooms: {},
  //   totalPrice: 0,
  // })
  // const [totalPrice, setTotalPrice] = useState(0)
  // const [totalGuests, setTotalGuests] = useState(0)
  // const [totalRooms, setTotalRooms] = useState(0)

  useEffect(() => {
    let newPrice = 0
    let newGuests = 0
    let newRooms = 0
    {
      Object?.keys(selectedRooms).map((selectedRoom) => {
        const rooms = selectedRooms[selectedRoom]
        return Object.keys(rooms).map((board) => {
          if (rooms?.[board]?.guests > 0 && rooms?.[board]?.rooms > 0) {
            newPrice += rooms?.[board]?.price * rooms?.[board]?.guests * rooms?.[board]?.rooms
            newGuests += rooms?.[board]?.guests
            newRooms += rooms?.[board]?.rooms
          }
        })
      })
    }
    setTotalPrice(newPrice)
    setTotalGuests(newGuests)
    setTotalRooms(newRooms)
  }, [selectedRooms])

  // Function to show and hide image
  // const handleShowImage = () => {
  //   if (showImage === false) {
  //     setShowImage(true)
  //   } else {
  //     setShowImage(false)
  //   }
  // }

  // const handleSecondImage = () => {
  //   if (showImage2 === false) {
  //     setShowImage2(true)
  //   } else {
  //     setShowImage2(false)
  //   }
  // }

  return (
    <div className="w-full pt-14">
      <h2>Zimmer- und Verpflegungsarten</h2>
      <div className="flex justify-center mx-auto mt-12">
        {/* div for the accordion card */}
        <div className="w-2/3 h-max">
          {/* card 1 */}
          {filteredooms?.map((room: any, index: number) => {
            const roomCategories = productDetails?.accommodation?.roomCategories?.find(
              (roomCategory: any) => roomCategory?.code == room?.roomCategoryCode,
            )
            const roomName = roomCategories?.name?.find((name: any) => name?.isoCode === 'de')?.text

            return (
              <div
                className="pb-5 mb-6 border bg-booking-primary-500 shadow-primary-100 rounded-booking-md border-booking-primaryBorder"
                key={index}
              >
                {/* part 1 */}
                <div className="flex justify-between w-full px-5 py-5">
                  <div className="flex">
                    <span className="font-normal text-booking-primary font-booking-secondary text-booking-2xl">
                      {roomName}
                    </span>
                    <span className="border borderColor-primary-100 ms-4"></span>
                    <span>
                      <img src="%PUBLIC_URL%/img/addUser.png" alt="UserImage" className="pt-2.5 w-5 ms-4" />
                    </span>
                    <span className="pt-1 text-booking-tertiary ms-4 opacity-70 text-booking-lg">
                      Max.: {roomCategories?.maximumOccupancy} Personen
                    </span>
                    <span>
                      <img src="%PUBLIC_URL%/img/resize_icon.png" alt="resize_image" className="pt-2.5 ms-4" />
                    </span>
                    <span className="font-booking-primary text-booking-lg font-normal text-booking-tertiary ps-1.5 pt-1 opacity-70">
                      {roomCategories?.surfaceAreaInSquareMeters} m<sup>2</sup>
                    </span>
                  </div>

                  {/* <div className="flex pt-0.5">
                    <span>
                      <img
                        src="%PUBLIC_URL%/img/down_arrow.png"
                        alt=""
                        className="pt-1.5 me-4 hover:cursor-pointer"
                      />
                    </span>
                  </div> */}
                </div>

                {showImage === true ? (
                  <div className="justify-between w-full px-5 py-5">
                    <div className="w-full border-b border-booking-primary-400"></div>
                    <div className="flex w-full py-5">
                      {/* Image div */}
                      <div>
                        <img src="%PUBLIC_URL%/img/image1.png" alt="image1" className="rounded-booking-lg" />
                      </div>

                      {/* text div */}
                      <div className="w-full pl-5">
                        {/* part 1 */}
                        <div className="flex">
                          <span>
                            <img src="%PUBLIC_URL%/img/watch.png" alt="watch" className="pt-1.5" />
                          </span>
                          <span className="font-normal text-booking-primary font-booking-primary text-booking-base ps-2">
                            Nur 1 Zimmer auf unserer Seite übrig
                          </span>
                        </div>

                        {/* part 2 */}
                        <div className="flex pt-1">
                          <span>
                            <img src="%PUBLIC_URL%/img/cup.png" alt="" />
                          </span>
                          <span className="font-normal text-booking-tertiary font-booking-primary ps-2">Frühstück</span>
                          <span>
                            <img src="%PUBLIC_URL%/img/tree.png" alt="tree_image" className="ps-3" />
                          </span>
                          <span className="font-normal text-booking-tertiary font-booking-primary ps-2">
                            Gartenblick
                          </span>
                          <span>
                            <img src="%PUBLIC_URL%/img/plus.png" alt="plus" className="ps-2" />
                          </span>
                        </div>

                        {/* part 3 */}
                        <div>
                          <span className="font-normal text-booking-tertiary font-booking-primary opacity-80">
                            Modern, reduziert und doch behaglich: Das lichtdurchflutete Doppelzimmer Alpin mit
                            Zirbenholzelementen bietet Doppelbett, Ausziehcouch und Badezimmer mit Dusche. Dazu den
                            Panoramabalkon mit bester Aussicht auf die umliegenden Berge.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                <div className="border-b border-booking-primary-400"></div>

                {room?.prices?.map((price, priceIndex) => {
                  const boardCode = price?.boardCode
                  const boardType = price?.boardType.find((type) => type.isoCode === 'de')?.text
                  const guestPrice = price?.guestPrices[0]?.price
                  return (
                    <Fragment key={priceIndex}>
                      {/* part 2 */}
                      <div className="flex justify-between w-full px-5 py-4">
                        <span className="font-normal text-booking-primary text-booking-xl font-booking-secondary lg:w-2/5">
                          {boardType} (3 - 4 Tage)
                        </span>
                        <div className="flex justify-center lg:w-2/5">
                          <span className="font-normal font-booking-primary text-booking-base text-booking-tertiary-300">
                            Gäste
                          </span>
                          <div className="relative flex w-max ps-2">
                            <div className="font-normal text-booking-tertiary text-booking-base font-booking-primary">
                              <select
                                name="select_guest"
                                id="select_guest"
                                className="w-16 px-2 border outline-none bg-booking-primary-500 border-booking-tertiary-400 rounded-booking-md text-booking-base hover:cursor-pointer"
                                style={{
                                  MozAppearance: 'none',
                                  WebkitAppearance: 'none',
                                }}
                                value={selectedRooms?.[roomCategories?.code]?.[boardCode]?.guests || 0}
                                onChange={(e) => {
                                  // setSelectedRooms()
                                  if (parseInt(e.target.value) > 0) {
                                    setSelectedRooms((prev) => ({
                                      ...prev,
                                      [roomCategories?.code]: {
                                        ...prev?.[roomCategories?.code],
                                        [boardCode]: {
                                          ...prev?.[roomCategories?.code]?.[boardCode],
                                          guests: parseInt(e.target.value),
                                          rooms:
                                            parseInt(e.target.value) < prev?.[roomCategories?.code]?.[boardCode]?.rooms
                                              ? parseInt(e.target.value)
                                              : !prev?.[roomCategories?.code]?.[boardCode]?.rooms
                                              ? 1
                                              : prev?.[roomCategories?.code]?.[boardCode]?.rooms,
                                          price: guestPrice,
                                        },
                                      },
                                    }))
                                  } else {
                                    setSelectedRooms((prev) => ({
                                      ...prev,
                                      [roomCategories?.code]: {
                                        ...prev?.[roomCategories?.code],
                                        [boardCode]: {
                                          ...prev?.[roomCategories?.code]?.[boardCode],
                                          guests: 0,
                                          rooms: 0,
                                          price: guestPrice,
                                        },
                                      },
                                    }))
                                  }
                                }}
                              >
                                {/* <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option> */}
                                {Array.from(Array(roomCategories?.maximumOccupancy + 1 || 5).keys()).map(
                                  (occupancy, occIndex) => {
                                    return <option key={occIndex}>{occupancy}</option>
                                  },
                                )}
                              </select>
                            </div>
                            <img
                              src="%PUBLIC_URL%/img/down_arrow.png"
                              alt=""
                              className="pt-1.5 w-4 absolute left-11 top-[2px] pointer-events-none"
                            />
                          </div>
                        </div>

                        <div className="flex justify-end lg:w-2/5">
                          <span className="font-normal font-booking-primary text-booking-base text-booking-tertiary-300">
                            Anzahl Zimmer
                          </span>
                          <div className="relative flex w-max ps-2">
                            <div className="font-normal text-booking-tertiary text-booking-base font-booking-primary">
                              <select
                                name="select_guest"
                                id="select_guest"
                                value={selectedRooms?.[roomCategories?.code]?.[boardCode]?.rooms || 0}
                                className="w-16 px-2 border outline-none bg-booking-primary-500 border-booking-tertiary-400 rounded-booking-md text-booking-base hover:cursor-pointer"
                                style={{
                                  MozAppearance: 'none',
                                  WebkitAppearance: 'none',
                                }}
                                onChange={(e) => {
                                  // setSelectedRooms()
                                  if (parseInt(e.target.value) > 0) {
                                    setSelectedRooms((prev) => ({
                                      ...prev,
                                      [roomCategories?.code]: {
                                        ...prev?.[roomCategories?.code],
                                        [boardCode]: {
                                          ...prev?.[roomCategories?.code]?.[boardCode],
                                          rooms: parseInt(e.target.value),
                                          price: guestPrice,
                                        },
                                      },
                                    }))
                                  } else {
                                    setSelectedRooms((prev) => ({
                                      ...prev,
                                      [roomCategories?.code]: {
                                        ...prev?.[roomCategories?.code],
                                        [boardCode]: {
                                          ...prev?.[roomCategories?.code]?.[boardCode],
                                          rooms: 0,
                                          guests: 0,
                                          price: 0,
                                        },
                                      },
                                    }))
                                  }
                                }}
                              >
                                {/* <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option> */}
                                {Array.from(Array(roomCategories?.maximumOccupancy + 1 || 5).keys()).map(
                                  (occupancy, occIndex) => {
                                    if (
                                      (selectedRooms?.[roomCategories?.code]?.[boardCode]?.guests || 0) >= occupancy
                                    ) {
                                      return <option key={occIndex}>{occupancy}</option>
                                    }
                                  },
                                )}
                              </select>
                            </div>
                            <img
                              src="%PUBLIC_URL%/img/down_arrow.png"
                              alt=""
                              className="pt-1.5 w-4 absolute left-11 top-[2px] pointer-events-none"
                            />
                          </div>
                        </div>
                      </div>

                      {/* part 3 */}
                      <div className="flex px-5">
                        <span>
                          <img src="%PUBLIC_URL%/img/cup.png" alt="cup_image" className="pt-0.5" />
                        </span>
                        <span className="font-normal text-booking-tertiary font-booking-primary text-booking-base ps-2">
                          {boardType}
                        </span>
                        <span className="text-booking-tertiary pt-0.5 ps-2">|</span>
                        <span className="pt-0.5 ps-2 text-booking-tertiary font-booking-primary font-normal">
                          <small>Kostenlose Stornierung bis Mittwoch, 08. 11. 2023</small>
                        </span>
                      </div>

                      {/* part 4 */}
                      <div className="flex justify-between px-5">
                        <div>
                          <span className="text-booking-tertiary text-[14px] font-normal underline">
                            Buchungsbedingungen & Konditionen
                          </span>
                        </div>
                        <div className="flex items-center">
                          <div className="text-booking-tertiary font-booking-primary font-normal pe-3 pt-0.5">
                            {' '}
                            <small>{nights} Nächte</small>
                          </div>
                          <div className="font-normal text-booking-primary font-booking-primary text-booking-lg">
                            {formatPrice(guestPrice, productDetails?.currency, 'de', 2, 2)}
                          </div>
                        </div>
                      </div>

                      <div className="my-5 border-b border-booking-primary-400 last:hidden"></div>
                    </Fragment>
                  )
                })}
              </div>
            )
          })}
        </div>

        {/* div for the card beside of accordion */}
        <div className="w-2/6 pl-6">
          <SummaryDetails
            products={products}
            rooms={rooms}
            adults={adults}
            arrival={arrival}
            departure={departure}
            nights={nights}
            productDetails={productDetails}
            selectedRooms={selectedRooms}
            setSelectedRooms={setSelectedRooms}
            totalPrice={totalPrice}
            setTotalPrice={setTotalPrice}
            totalGuests={totalGuests}
            setTotalGuests={setTotalGuests}
            totalRooms={totalRooms}
            setTotalRooms={setTotalRooms}
            showBookNow={true}
          ></SummaryDetails>
          {/* <div className="border border-booking-primaryBorder rounded-booking-md h-max bg-[#F5F7FA]">
            <h6 className="p-5">Buchungszusammenfassung</h6>

            <div className="flex justify-between px-5 py-3 mt-3 MTS-w-full">
              <span className="font-bold text-booking-primary font-booking-primary text-booking-lg">
                {productDetails?.accommodation?.name}
              </span>
              <span
                className="font-normal cursor-pointer text-booking-primary font-booking-primary text-booking-lg hover:underline"
                onClick={() => navigate('/')}
              >
                Ändern
              </span>
            </div>

            <div className="w-full p-0 my-3 border-b border-booking-primaryBorder"></div>
            <div className="px-5 py-3">
              {Object?.keys(selectedRooms).map((selectedRoom) => {
                const rooms = selectedRooms[selectedRoom]
                return Object.keys(rooms).map((board) => {
                  console.log({ rooms, board: rooms?.[board] })
                  if (rooms?.[board]?.guests > 0 && rooms?.[board]?.rooms > 0) {
                    return (
                      <>
                        <span
                          key={selectedRoom + board}
                          className="text-booking-tertiary font-booking-primary text-booking-lg opacity-80"
                        >
                          {rooms?.[board]?.rooms} Zimmer für {nights} Nächte : {rooms?.[board]?.guests} Erwachsene
                        </span>
                        <br />
                      </>
                    )
                  } else {
                    return null
                  }
                })
              })}

              <div className="flex justify-between mt-3 MTS-w-full">
                <div>
                  <h6>Aufenthaltsdauer</h6>
                  <div className="mt-2 font-normal text-booking-tertiary font-booking-primary text-booking-lg opacity-80">
                    {arrDays[arrival?.getDay()]}, {arrival.getDate()}. {arrMonths[arrival?.getMonth()]}{' '}
                    {arrival?.getFullYear()} bis
                  </div>
                  <div className="font-normal text-booking-tertiary font-booking-primary text-booking-lg opacity-80">
                    {arrDays[departure?.getDay()]}, {departure.getDate()}. {arrMonths[departure?.getMonth()]}{' '}
                    {departure?.getFullYear()}
                  </div>
                </div>
                <div
                  className="font-normal cursor-pointer text-booking-primary font-booking-primary text-booking-lg hover:underline"
                  onClick={() => navigate('/')}
                >
                  Ändern
                </div>
              </div>
            </div>
            <div className="w-full p-0 my-3 border-b border-booking-primaryBorder"></div>
            <div className="px-5 py-3">
              <span className="font-normal font-booking-secondary text-booking-xl text-booking-primary">
                Steuern und Gebühren
              </span>

              <div className="mt-3">
                <div className="flex justify-between">
                  <span className="font-normal text-booking-tertiary text-booking-lg font-booking-primary">
                    Orts-/Kurtaxe
                  </span>
                  <span className="font-normal text-booking-tertiary text-booking-lg font-booking-primary">
                    {formatPrice(
                      (productDetails?.termsAndConditions?.touristTaxAmount || 15) * totalGuests,
                      productDetails?.currency,
                      'de',
                      2,
                      2,
                    )}
                  </span>
                </div>
                <div>
                  <span className="font-normal text-booking-tertiary text-booking-lg font-booking-primary">
                    {formatPrice(
                      productDetails?.termsAndConditions?.touristTaxAmount || 15,
                      productDetails?.currency,
                      'de',
                      2,
                      2,
                    )}{' '}
                    Pro Person
                  </span>
                </div>
              </div>
            </div>
            <div className="w-full p-0 my-3 border-b border-booking-primaryBorder"></div>
            <div className="px-5 py-3">
              <div className="flex justify-between">
                <div>
                  <span className="font-normal text-booking-primary font-booking-secondary text-booking-2xl">
                    Gesamt
                  </span>
                </div>
                <div>
                  <div className="font-normal text-booking-primary font-booking-secondary text-booking-3xl">
                    {formatPrice(
                      totalPrice + (productDetails?.termsAndConditions?.touristTaxAmount || 15) * totalGuests,
                      productDetails?.currency,
                      'de',
                      2,
                      2,
                    )}
                  </div>
                  <div className="relative flex items-end justify-end w-full">
                    <button
                      onClick={() => navigate('/contactForm')}
                      className="right-0 px-6 pt-4 pb-3 mt-4 font-bold leading-none rounded-full text-booking-primary bg-booking-secondary font-booking-heading"
                    >
                      Book Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default RoomDetails

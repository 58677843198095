import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import SummaryDetails from '../../SummaryDetails/v_1/SummeryDetails'
import Breadcrumbs from '../../Breadcrumbs/v_1/Breadcrumbs'
import Stepper from '../../Stepper/v_1/Stepper'

const InsuranceDeatils = ({
  products,
  rooms,
  adults,
  arrival,
  departure,
  nights,
  productDetails,
  selectedRooms,
  setSelectedRooms,
  totalPrice,
  setTotalPrice,
  totalGuests,
  setTotalGuests,
  totalRooms,
  setTotalRooms,
}) => {
  const navigate = useNavigate()
  const [checked, setChecked] = useState(false)
  const [checkedEmail, setCheckedEmail] = useState(false)

  const handleChange = (event: any) => {
    setChecked(event.currentTarget.checked)
  }

  const handleChangeEmail = (event: any) => {
    setCheckedEmail(event.currentTarget.checked)
  }

  const [select, setSelect] = useState(0)
  const handleSelect = (event: any) => {
    setSelect(event.currentTarget.value)
  }
  return (
    <div className="mx-auto my-4 font-booking-primary">
      <Breadcrumbs />
      <div className="pt-10 text-booking-tertiary">
        <Stepper />
      </div>
      <div className="w-full gap-4 pt-16">
        <h2 className="font-bold text-booking-heading text-booking-4xl font-booking-secondary">Fast gebucht!</h2>
      </div>
      <div className="flex w-full">
        <div className="w-2/3 h-max">
          <div className="mt-6 border border-booking-primaryBorder rounded-booking-lg">
            <div className="flex justify-between w-full p-4 pb-4 font-bold border-b text-booking-heading font-booking-secondary border-booking-primaryBorder text-booking-xl">
              <p className="font-normal text-booking-2xl">Details des Reisenden</p>
              <p
                className="font-normal cursor-pointer text-booking-lg hover:underline"
                onClick={() => navigate('/contactForm')}
              >
                Ändern
              </p>
            </div>

            <div>
              <div className="p-3">
                <div className="w-max">
                  <strong className="font-bold text-booking-xl text-booking-heading">Hauptreisender:</strong>
                </div>
                <div className="ps-10">
                  <div>
                    <strong className="font-bold text-booking-lg text-booking-tertiary">Name:</strong>
                    <span className="text-booking-tertiary"> Mr. Andrew Lorem</span>
                    {/* <span className="px-3 text-booking-tertiary"> |</span> */}
                    <div>
                      <strong className="font-bold text-booking-tertiary text-booking-lg">Geburtsdatum:</strong>
                      <span className="text-booking-tertiary"> 15-11-1971</span>
                    </div>
                  </div>
                  <strong className="font-bold text-booking-tertiary text-booking-lg">Telefon nummer:</strong>
                  <span className="text-booking-tertiary"> 06 736 9389</span>
                  {/* <span className="px-3 text-booking-tertiary"> |</span> */}
                  <div>
                    <strong className="font-bold text-booking-tertiary text-booking-lg">E-mail:</strong>
                    <span className="text-booking-tertiary"> andrewlorem12@gmail.com</span>
                  </div>
                </div>
              </div>

              <div className="p-3">
                <div className="w-max">
                  <strong className="font-bold text-booking-xl text-booking-heading">Reisender 2:</strong>
                </div>
                <div className="ps-10">
                  <div>
                    <strong className="font-bold text-booking-lg text-booking-tertiary">Name:</strong>
                    <span className="text-booking-tertiary"> Mr. Andrew Lorem</span>
                    {/* <span className="px-3 text-booking-tertiary"> |</span> */}
                    <div>
                      <strong className="font-bold text-booking-tertiary text-booking-lg">Geburtsdatum:</strong>
                      <span className="text-booking-tertiary"> 15. 11. 1971</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-3">
                <div className="w-max">
                  <strong className="font-bold text-booking-xl text-booking-heading">Reisender 3:</strong>
                </div>
                <div className="ps-10">
                  <div>
                    <strong className="font-bold text-booking-lg text-booking-tertiary">Name:</strong>
                    <span className="text-booking-tertiary"> Mr. Andrew Lorem</span>
                    {/* <span className="px-3 text-booking-tertiary"> |</span> */}
                    <div>
                      <strong className="font-bold text-booking-tertiary text-booking-lg">Geburtsdatum:</strong>
                      <span className="text-booking-tertiary"> 21. 06. 1969</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10 border border-booking-primaryBorder rounded-booking-lg">
            <p className="flex items-center w-full mt-5 mb-3 ml-4 text-booking-tertiary font-booking-secondary custom-checkbox">
              <input
                className="accent-[#004988]"
                onChange={handleChange}
                type="checkbox"
                id="insurance"
                name="insurance"
              />
              <span className="-mt-1 checkmark"></span>
              <label htmlFor="insurance" className="">
                Meine Reise für absichern
              </label>
            </p>
            <div>
              <p className="px-4 pb-4 text-booking-base">
                Ich habe die Informationen zum Hotelstorno Plus der Europäischen Reiseversicherung AG gelesen,
                akzeptiere diese und bin mit der Zusendung dieser Informationen an meine E-Mail-Adresse einverstanden.
                Dabei handelt es sich um das Informationsblatt zum Versicherungsprodukt, dem ergänzenden
                vorvertraglichen Informationsblatt zum Versicherungsprodukt, die Leistungsbeschreibung, die
                Versicherungsbedingungen, die Informationen zu den Rücktrittsrechten, den Beschwerdemöglichkeiten und
                zur Datenverarbeitung.
              </p>
            </div>
            <div className={checked ? '' : 'hidden'}>
              <div className="flex justify-between px-5">
                <div className="relative w-1/2 bg-booking-primary rounded-booking-lg">
                  <div className="absolute w-full p-3 text-center mt-7 text-booking-3xl bg-booking-secondary">
                    <h4>Premium</h4>
                  </div>
                  <div className="pt-32 text-center p-9">
                    <p className="text-booking-6xl text-booking-secondary">EUR 10,00</p>
                    <p className="text-booking-primary-300 text-booking-base">Prämie ( inkl. St.)</p>
                  </div>
                  <div className="px-9 pb-9">
                    <div className="flex">
                      <img src="%PUBLIC_URL%/img/icon/check_double.svg" alt="" />
                      <span className="text-sm leading-7 text-booking-primary-300 ps-3">Reisestorno</span>
                    </div>
                    <div className="flex">
                      <img src="%PUBLIC_URL%/img/icon/check_double.svg" alt="" />
                      <span className="text-sm leading-7 text-booking-primary-300 ps-3">kostenlose Covid-Deckung</span>
                    </div>
                    <div className="flex">
                      <img src="%PUBLIC_URL%/img/icon/check_double.svg" alt="" />
                      <span className="text-sm leading-7 text-booking-primary-300 ps-3">Erweiterte Stornogründe</span>
                    </div>
                    <div className="flex">
                      <img src="%PUBLIC_URL%/img/icon/check_double.svg" alt="" />
                      <span className="text-sm leading-7 text-booking-primary-300 ps-3">Reiseabbruch</span>
                    </div>
                    <div className="flex">
                      <img src="%PUBLIC_URL%/img/icon/check_double.svg" alt="" />
                      <span className="text-sm leading-7 text-booking-primary-300 ps-3">Verspätete Anreise</span>
                    </div>
                    <div className="flex">
                      <img src="%PUBLIC_URL%/img/icon/check_double.svg" alt="" />
                      <span className="text-sm leading-7 text-booking-primary-300 ps-3">
                        Unfreiwillige Urlaubsverlängerung
                      </span>
                    </div>
                    <div className="flex">
                      <img src="%PUBLIC_URL%/img/icon/check_double.svg" alt="" />
                      <span className="text-sm leading-7 text-booking-primary-300 ps-3">Suche und Bergung</span>
                    </div>
                    <div className="flex">
                      <img src="%PUBLIC_URL%/img/icon/check_double.svg" alt="" />
                      <span className="text-sm leading-7 text-booking-primary-300 ps-3">Leistungen nach Unfall</span>
                    </div>
                  </div>
                  <div className="text-center pb-7">
                    <button className="px-10 pt-3 pb-2 font-semibold rounded-full bg-booking-secondary text-booking-primary text-booking-lg font-booking-heading">
                      Ausgewählt
                    </button>
                  </div>
                </div>
                <div className="relative w-1/2 pl-4">
                  <div className="relative w-full border-2 rounded-booking-lg border-booking-primaryBorder">
                    <h5 className="absolute w-full p-3 text-center mt-7 bg-booking-primary">
                      <span className="font-normal text-booking-primary-300 text-booking-3xl">Plus</span>
                    </h5>
                    <div className="pt-32 text-center p-9">
                      <p className="text-booking-6xl text-booking-primary">EUR 8,00</p>
                      <p className="text-booking-primary text-booking-lg">Premium (inkl. St.)</p>
                    </div>
                    <div className="px-9 pb-9">
                      <div className="flex">
                        <img src="%PUBLIC_URL%/img/icon/check_double_blue.svg" alt="" />
                        <span className="text-sm leading-7 text-booking-primary ps-3">Reisestorno</span>
                      </div>
                      <div className="flex">
                        <img src="%PUBLIC_URL%/img/icon/check_double_blue.svg" alt="" />
                        <span className="text-sm leading-7 text-booking-primary ps-3">kostenlose Covid-Deckung</span>
                      </div>
                      <div className="flex">
                        <img src="%PUBLIC_URL%/img/icon/check_double_blue.svg" alt="" />
                        <span className="text-sm leading-7 text-booking-primary ps-3">Erweiterte Stornogründe</span>
                      </div>
                      <div className="flex">
                        <img src="%PUBLIC_URL%/img/icon/check_double_blue.svg" alt="" />
                        <span className="text-sm leading-7 text-booking-primary ps-3">Reiseabbruch</span>
                      </div>
                      <div className="flex">
                        <img src="%PUBLIC_URL%/img/icon/check_double_blue.svg" alt="" />
                        <span className="text-sm leading-7 text-booking-primary ps-3">Verspätete Anreise</span>
                      </div>
                      <div className="flex">
                        <img src="%PUBLIC_URL%/img/icon/check_double_blue.svg" alt="" />
                        <span className="text-sm leading-7 text-booking-primary ps-3">
                          Unfreiwillige Urlaubsverlängerung
                        </span>
                      </div>
                      <div className="flex">
                        <img src="%PUBLIC_URL%/img/icon/check_double_blue.svg" alt="" />
                        <span className="text-sm leading-7 text-booking-primary ps-3">Suche und Bergung</span>
                      </div>
                      <div className="flex">
                        <img src="%PUBLIC_URL%/img/icon/check_double_blue.svg" alt="" />
                        <span className="text-sm leading-7 text-booking-primary ps-3">Leistungen nach Unfall</span>
                      </div>
                    </div>
                    <div className="text-center pb-7">
                      <button className="px-10 pt-3 pb-2 font-semibold rounded-full bg-booking-secondary text-booking-primary text-booking-lg font-booking-heading">
                        Auswählen
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={
                  (select >= 1 ? '' : 'hidden') + ' m-5 border border-booking-primaryBorder rounded-booking-lg mt-6'
                }
              >
                <p className="w-full p-4 pb-4 font-bold border-b text-booking-heading font-booking-secondary border-booking-primaryBorder text-booking-xl">
                  Main passanger
                </p>
                <form className="p-4 text-booking-tertiary">
                  <div className="flex justify-between w-full gap-5">
                    <span className="relative w-1/5">
                      <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">
                        Anrede
                      </label>
                      <select
                        name="gender"
                        className="w-full px-3 pt-6 pb-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                      >
                        <option value=""></option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                      </select>
                    </span>
                    <span className="relative w-2/5">
                      <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">
                        Vorname*
                      </label>
                      <input
                        type="text"
                        name="firstname"
                        className="w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                      />
                    </span>
                    <span className="relative w-2/5">
                      <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">
                        Nachname*
                      </label>
                      <input
                        type="text"
                        name="lastname"
                        className="w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                      />
                    </span>
                  </div>
                  <div className="flex gap-5 pt-6">
                    <span className="relative w-1/2">
                      <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">
                        E-Mail*
                      </label>
                      <input
                        type="text"
                        name="email"
                        className="w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                      />
                    </span>
                    <span className="relative w-1/2">
                      <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">
                        Straße*
                      </label>
                      <select
                        name="gender"
                        className="w-full px-3 pt-6 pb-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                      >
                        <option value="">Aaland-Inseln</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>
                    </span>
                  </div>
                  <div className="flex gap-5 pt-6">
                    <span className="relative w-2/6">
                      <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">PLZ</label>
                      <input
                        type="text"
                        name="plz"
                        className="w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                      />
                    </span>

                    <span className="relative w-2/6">
                      <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">Ort</label>
                      <input
                        type="text"
                        name="ort"
                        className="w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                      />
                    </span>

                    <span className="relative w-2/6">
                      <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">
                        Staat
                      </label>
                      <input
                        type="text"
                        name="staat"
                        className="w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                      />
                    </span>
                  </div>
                </form>
              </div>

              <div
                className={
                  (select >= 2 ? '' : 'hidden') + ' m-5 border border-booking-primaryBorder rounded-booking-lg mt-6'
                }
              >
                <p className="w-full p-4 pb-4 font-bold border-b text-booking-heading font-booking-secondary border-booking-primaryBorder text-booking-xl">
                  Passanger 2
                </p>

                <form className="p-4 text-booking-tertiary">
                  <div className="flex justify-between w-full gap-5">
                    <span className="relative w-1/5">
                      <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">
                        Anrede
                      </label>
                      <select
                        name="gender"
                        className="w-full px-3 pt-6 pb-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                      >
                        <option value=""></option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                      </select>
                    </span>
                    <span className="relative w-2/5">
                      <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">
                        Vorname*
                      </label>
                      <input
                        type="text"
                        name="firstname"
                        className="w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                      />
                    </span>
                    <span className="relative w-2/5">
                      <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">
                        Nachname*
                      </label>
                      <input
                        type="text"
                        name="lastname"
                        className="w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                      />
                    </span>
                  </div>
                  <div className="flex gap-5 pt-6">
                    <span className="relative w-2/6">
                      <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">PLZ</label>
                      <input
                        type="text"
                        name="plz"
                        className="w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                      />
                    </span>

                    <span className="relative w-2/6">
                      <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">Ort</label>
                      <input
                        type="text"
                        name="ort"
                        className="w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                      />
                    </span>

                    <span className="relative w-2/6">
                      <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">
                        Staat
                      </label>
                      <input
                        type="text"
                        name="staat"
                        className="w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                      />
                    </span>
                  </div>
                </form>
              </div>
              <div
                className={
                  (select >= 3 ? '' : 'hidden') + ' m-5 border border-booking-primaryBorder rounded-booking-lg mt-6'
                }
              >
                <p className="w-full p-4 pb-4 font-bold border-b text-booking-heading font-booking-secondary border-booking-primaryBorder text-booking-xl">
                  Passanger 3
                </p>

                <form className="p-4 text-booking-tertiary">
                  <div className="flex justify-between w-full gap-5">
                    <span className="relative w-1/5">
                      <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">
                        Anrede
                      </label>
                      <select
                        name="gender"
                        className="w-full px-3 pt-6 pb-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                      >
                        <option value=""></option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                      </select>
                    </span>
                    <span className="relative w-2/5">
                      <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">
                        Vorname*
                      </label>
                      <input
                        type="text"
                        name="firstname"
                        className="w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                      />
                    </span>
                    <span className="relative w-2/5">
                      <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">
                        Nachname*
                      </label>
                      <input
                        type="text"
                        name="lastname"
                        className="w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                      />
                    </span>
                  </div>
                  <div className="flex gap-5 pt-6">
                    <span className="relative w-2/6">
                      <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">PLZ</label>
                      <input
                        type="text"
                        name="plz"
                        className="w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                      />
                    </span>

                    <span className="relative w-2/6">
                      <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">Ort</label>
                      <input
                        type="text"
                        name="ort"
                        className="w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                      />
                    </span>

                    <span className="relative w-2/6">
                      <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">
                        Staat
                      </label>
                      <input
                        type="text"
                        name="staat"
                        className="w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                      />
                    </span>
                  </div>
                </form>
              </div>

              <div className="p-5">
                <select
                  name="gender"
                  onChange={handleSelect}
                  className="w-full p-3 bg-transparent border outline-none text-booking-2xl text-booking-primary border-booking-primaryBorder rounded-booking-lg"
                >
                  <option value="">Passagier auswählen</option>
                  <option value="Hauptreisender">Hauptreisender</option>
                  <option value="1">Reisender 2</option>
                  <option value="2">Reisender 3</option>
                </select>
              </div>
            </div>

            <div>
              {checked && (
                <p className="flex items-center w-full mx-4 my-4 text-booking-tertiary font-booking-secondary custom-checkbox">
                  <input className="accent-[#004988]" type="checkbox" name="inEmai" id="inEmail" onChange={handleChangeEmail} />
                  <span className="-mt-1 checkmark"></span>
                  <label htmlFor="inEmail">Ich bestätige E-Mails von der Versicherungsgesellschaft zu erhalten</label>
                </p>
              )}
            </div>
          </div>

          <div className="relative flex justify-end w-full my-6">
            <button
              onClick={() => navigate('/paymentdetails')}
              className="px-8 pt-3 pb-2 mb-6 font-bold rounded-full text-booking-primary bg-booking-secondary font-booking-heading"
            >
              Nächste
            </button>
          </div>
        </div>
        <div className="w-2/6 pl-6">
          <SummaryDetails
            products={products}
            rooms={rooms}
            adults={adults}
            arrival={arrival}
            departure={departure}
            nights={nights}
            productDetails={productDetails}
            selectedRooms={selectedRooms}
            setSelectedRooms={setSelectedRooms}
            totalPrice={totalPrice}
            setTotalPrice={setTotalPrice}
            totalGuests={totalGuests}
            setTotalGuests={setTotalGuests}
            totalRooms={totalRooms}
            setTotalRooms={setTotalRooms}
	showFlightDetails={true}
          />
        </div>
      </div>
    </div>
  )
}

export default InsuranceDeatils

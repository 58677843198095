import React from 'react'
import { useNavigate } from 'react-router'
import Breadcrumbs from '../../Breadcrumbs/v_1/Breadcrumbs'
import RoomDetails from '../../RoomDetails/v_1/RoomDetails'
import { daysDiff, formatDate, formatPrice } from '../../../utils/reusableFunctions'

const HotelDetailCards = ({
  products,
  rooms,
  adults,
  arrival,
  departure,
  nights,
  productDetails,
  selectedRooms,
  setSelectedRooms,
  totalPrice,
  setTotalPrice,
  totalGuests,
  setTotalGuests,
  totalRooms,
  setTotalRooms,
}) => {
  const DaysString = {
    de: 'SO,MO,DI,MI,DO,FR,SA',
    it: 'DOM,LUN,MAR,MER,GIO,VEN,SAB',
    en: 'SU,MO,TU,WE,TH,FR,SA',
  }
  const arrDays = DaysString['de'].split(',')

  // // get params values from URL
  // const params = new URLSearchParams(window.location.search)
  // const adultsCountParam = params.get('adultsCount')
  // const childrenCountParam = params.get('childrenCount')

  const navigate = useNavigate()
  // const productCode = `PK100007`
  // const adults = adultsCountParam ? parseInt(adultsCountParam) : 2
  // const arrivalParam = params.get('arrival')
  // const departureParam = params.get('departure')
  // const arrival = arrivalParam ? new Date(arrivalParam) : new Date('2023-11-20T18:30:00.000Z')
  // const departure = departureParam ? new Date(departureParam) : new Date('2023-11-23T18:30:00.000Z')
  // const nights = arrival && departure ? daysDiff(arrival, departure) : 7
  // const productDetails = products.find((product) => product?.productCode === productCode)

  console.log({ productDetails })
  const location = productDetails.accommodation?.touristicDestinationType?.find(({ isoCode }) => isoCode === 'de')?.text
  const flights = productDetails.specialServices?.filter((service) => service?.specialServiceType === 0)
  const flightsString =
    flights?.length > 0 &&
      flights
        ?.map((flight) => flight?.description?.find(({ isoCode }) => isoCode === 'de')?.text)
        ?.sort()
        ?.join('<br>')
        ?.replaceAll(' - ', '-')
        ?.replaceAll('-', ' - ')

  return (
    <div className="mx-auto my-4">
      <Breadcrumbs />
      <div className="w-full border border-booking-primaryBorder rounded-booking-lg p-7">
        <div className="flex justify-between pb-5">
          <div>
            <h3>{productDetails?.accommodation?.name}</h3>
            <span className="text-booking-tertiary MTS_text-booking-lg">{location}</span>
          </div>
          <div className="flex">
            <img className="pr-3" src="%PUBLIC_URL%/img/icon/heart1.svg" alt="" />
            <img src="%PUBLIC_URL%/img/icon/share.svg" alt="" />
          </div>
        </div>
        <div className="flex">
          <div className="w-2/3">
            <div>
              <img
                className="border rounded-booking-lg h-[497px] w-full object-cover object-center"
                src={productDetails?.images[0]?.url}
                width="100%"
                height="497px"
                alt="room1"
              />
            </div>
            <div className="flex pt-5">
              {productDetails?.images?.slice(1)?.map((img, index) => {
                return (
                  <div
                    className={`h-[120px] xl:h-[149px] w-1/5 pr-2 ${
                      index === productDetails?.images?.length - 2 ? 'relative' : ''
                    }`}
                  >
                    <img
                      className={`border rounded-booking-lg h-[149px] object-cover object-center ${
                        index === productDetails?.images?.length - 2 ? 'xl:h-[149px] w-full absolute' : ''
                      }`}
                      src={img?.url}
                      width="177"
                      height="149"
                      alt="room1"
                    />
                    {/* {index === productDetails?.images?.length - 2 && (
                      <div className="absolute flex justify-center w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                        <span className="p-1 font-bold text-white text-booking-lg">View all</span>{' '}
                        <img src="%PUBLIC_URL%/img/icon/arrows.svg" alt="" />
                      </div>
                    )} */}
                  </div>
                )
              })}
              {/* <div className="h-[120px] xl:h-[149px] w-1/5 pr-2 relative">
                <div>
                  <img
                    className="border rounded-booking-lg h-[120px] xl:h-[149px] w-full absolute"
                    src="%PUBLIC_URL%/img/hotelroom6.png"
                    width="177"
                    height="149"
                    alt="room1"
                  />
                  <div className="absolute flex justify-center w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                    <span className="p-1 font-bold text-white text-booking-lg">View all</span>{' '}
                    <img src="%PUBLIC_URL%/img/icon/arrows.svg" alt="" />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="w-2/6 pl-5">
            <div className="bg-[#F5F7FA] rounded-booking-lg p-5">
              <div>
                <span className="inline-block mb-1 font-bold MTS_text-booking-lg text-booking-primary">
                  Bozen-Olbia
                </span>
                <span className="block leading-6 text-booking-base text-booking-tertiary ">
                  {adults} Erwachsene-{nights} Nachte
                </span>
                {/* <span className="block text-booking-base text-booking-tertiary ">Reiseteilnehmer andern</span> */}
              </div>
              <hr className="h-px my-3 border-booking-primaryBorder" />
              {/* <div>
                <span className="inline-block mb-1 font-bold MTS_text-booking-lg text-booking-primary">Abflug</span>
                <span className="block leading-6 text-booking-base text-booking-tertiary ">
                  {arrDays[arrival?.getDay()]}. {formatDate(arrival, 'de')} {arrival.getHours()}:{arrival.getMinutes()}
                </span>

                <span className="block leading-5 text-booking-base text-booking-tertiary ">Ein kleines</span>
                <span className="block text-booking-base text-booking-tertiary">Handgepackstuck</span>
              </div> */}

              <span className="inline-block mb-1 font-bold MTS_text-booking-lg text-booking-primary">Reisedetails</span>
              <div className="block text-booking-base text-booking-tertiary">
                {/* {productDetails?.specialServices?.map((service) => (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: service?.description?.find((desc) => desc.isoCode === 'de')?.text || '',
                    }}
                  ></div>
                ))} */}
                <p dangerouslySetInnerHTML={{ __html: flightsString }}></p>
              </div>

              {/* <div>
                <span className="inline-block mb-1 font-bold MTS_text-booking-lg text-booking-primary">Ruckflug</span>
                <span className="block leading-6 text-booking-base text-booking-tertiary ">
                  {arrDays[arrival?.getDay()]}. {formatDate(departure, 'de')} {departure.getHours()}:
                  {departure.getMinutes()}
                </span>
                <span className="block leading-5 text-booking-base text-booking-tertiary ">Ein kleines</span>
                <span className="block text-booking-base text-booking-tertiary">Handgepackstuck</span>
              </div> */}
              {/* <div className="block text-booking-base text-booking-tertiary">
                {productDetails?.specialServices?.map((service) => (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: service?.description?.find((desc) => desc.isoCode === 'de')?.text || '',
                      }}
                    ></div>
                  ))}
                </div> */}

              {/* <hr className="h-px my-3 border-booking-primaryBorder" /> */}

              {/* <div>
                <span className="inline-block mb-1 font-bold MTS_text-booking-lg text-booking-primary">
                  Zimmer / Verpflegung
                </span>
                <span className="block text-booking-base text-booking-tertiary">
                  {productDetails?.accommodation?.roomCategories?.[0]?.name?.[0]?.text},{' '}
                  {productDetails?.boardDescription?.minimalBoardType?.[0]?.text}
                </span>
              </div> */}

              <hr className="h-px my-3 border-booking-primaryBorder" />

              <div>
                <span className="inline-block mb-1 font-bold MTS_text-booking-lg text-booking-primary">
                  Preis pro Person
                </span>
                <span className="block text-booking-base text-booking-tertiary">
                  {/* {productDetails?.pricePerAdult} {currency_symbols(productDetails?.currency || 'EUR')} */}
                  {formatPrice(productDetails?.pricePerAdult, productDetails?.currency, 'de', 2, 2)}
                </span>
              </div>
              <hr className="h-px my-4" />

              <div>
                <span className="inline-block mb-1 font-bold MTS_text-booking-lg text-booking-primary">Gesamtreis</span>
                <span className="block font-extrabold text-booking-primary text-booking-2xl">
                  {/* {productDetails?.pricePerAdult && productDetails?.pricePerAdult * adults}{' '}
                  {currency_symbols(productDetails?.currency || 'EUR')} */}
                  {formatPrice(
                    productDetails?.pricePerAdult && productDetails?.pricePerAdult * adults,
                    productDetails?.currency,
                    'de',
                    2,
                    2,
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex pt-6 mb-4">
          <div className="pr-7">
            <div>
              <span className="block py-3 text-booking-primary text-booking-xl">Hotelbewertungen</span>
              <div className="flex items-center">
                <div className="flex p-3 border-2 border-solid border-booking-tertiary-900 rounded-booking-lg w-max">
                  <div className="flex text-booking-lg text-booking-tertiary border-e-2 pe-3">
                    <img src="%PUBLIC_URL%/img/icon/like.svg" alt="likeicon" className="px-2" />
                    <span className="px-2">
                      {productDetails?.accommodation?.accommodationReviews?.[0]?.recommendationRate || '100'}%
                    </span>
                  </div>
                  <div className="flex text-booking-lg text-booking-tertiary flex-nowrap w-max">
                    <img src="%PUBLIC_URL%/img/icon/sun.svg" alt="likeicon" className="px-2" />
                    <span className="px-2">{productDetails?.accommodation?.starCategory || '4'} / 5</span>
                  </div>
                </div>
                <div className="pl-6">
                  <span className="text-booking-tertiary">
                    {productDetails?.accommodation?.accommodationReviews?.[0]?.reviewCount || '282'} Bewertungen
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="border-l pl-7">
            <div>
              <span className="block py-3 text-booking-primary text-booking-xl">Zahlungsmöglichkeiten</span>
              <img src="%PUBLIC_URL%/img/icon/visa.png" alt="" />
            </div>
          </div>
        </div>

        {/* <div>
          <hr />
          <div className="pt-5">
            <span className="block pb-3 text-booking-primary text-booking-xl">Kurzbeschreibung</span>
            <p className="text-booking-tertiary text-booking-base">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
              industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release
              of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software
              like Aldus PageMaker including versions of Lorem Ipsum.{' '}
            </p>
          </div>
        </div> */}
      </div>
      <RoomDetails
        products={products}
        rooms={rooms}
        adults={adults}
        arrival={arrival}
        departure={departure}
        nights={nights}
        productDetails={productDetails}
        selectedRooms={selectedRooms}
        setSelectedRooms={setSelectedRooms}
        totalPrice={totalPrice}
        setTotalPrice={setTotalPrice}
        totalGuests={totalGuests}
        setTotalGuests={setTotalGuests}
        totalRooms={totalRooms}
        setTotalRooms={setTotalRooms}
      />
      {/* <div className='relative items-end justify-end w-full my-6'>
      <button onClick={() => navigate("/contactForm")} className='right-0 px-6 py-3 my-6 mr-4 border rounded-full text-booking-primary bg-booking-secondary'>Next</button>
    </div> */}
    </div>
  )
}

export default HotelDetailCards

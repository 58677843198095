import { useNavigate } from 'react-router'
import { formatPrice } from '../../../utils/reusableFunctions'

const BookingConfirmation = ({
  products,
  rooms,
  adults,
  arrival,
  departure,
  nights,
  productDetails,
  selectedRooms,
  setSelectedRooms,
  totalPrice,
  setTotalPrice,
  totalGuests,
  setTotalGuests,
  totalRooms,
  setTotalRooms,
}) => {
  const navigate = useNavigate()
  const DaysString = {
    de: 'Sonntag,Montag,Dienstag,Mittwoch,Donnerstag,Freitag,Samstag',
  }
  const arrDays = DaysString['de'].split(',')

  const MonthsString = {
    de: 'Januar,Februar,März,April,Mai,Juni,Juli,August,September,Oktober,November,Dezember',
  }
  const arrMonths = MonthsString['de'].split(',')

  return (
    <div className="items-center w-full gap-4 pt-16 mx-auto text-center">
      <h2 className="p-5 font-normal text-booking-heading text-booking-6xl font-booking-secondary">
        Vielen Dank für Ihre Buchung !
      </h2>
      <div className="max-w-[574px] border border-booking-primaryBorder w-full mx-auto my-24 relative rounded-booking-md">
        <div className=" absolute mt-[-45px] w-full mx-auto align-middle">
          <img className="mx-auto bg-white" src="%PUBLIC_URL%/img/icon/checkmark1.svg" alt="" />
        </div>
        <h4 className="mt-20 ">Zusammenfassung der Buchungen</h4>
        <div className="w-full pb-3 text-center">
          <p
            className="pt-5 mx-auto font-bold border-b cursor-pointer text-booking-lg text-booking-primary w-max"
            onClick={() => navigate('/')}
          >
            {productDetails?.accommodation?.name}
          </p>
        </div>
        <p className="text-booking-tertiary">Aufenthaltsdauer</p>
        {/* <p className="p-2 border-b text-booking-tertiary">1 Zimmer für 7 Nächte für 2 Erwachsene</p> */}
        <p className="p-2 border-b text-booking-tertiary">
          {totalRooms} Zimmer für {nights} Nächte : {totalGuests} Erwachsene
        </p>

        <div className="flex justify-center border-b border-booking-primaryBorder text-booking-tertiary">
          <div className="px-4 my-4 border-e">
            <div className="flex justify-center">
              <img className="w-max" src="%PUBLIC_URL%/img/icon/airplane.svg" alt="" />
              <p className="w-max ps-1">Anreise:</p>
            </div>
            <p className="leading-9">
              {arrDays[arrival?.getDay()]}, {arrival.getDate()}. {arrMonths[arrival?.getMonth()]}{' '}
              {arrival?.getFullYear()}
            </p>
          </div>

          <div className="px-4 my-4 ">
            <div className="flex justify-center">
              <img className="w-max " src="%PUBLIC_URL%/img/icon/airplane1.svg" alt="" />
              <p className="w-max ps-1">Abreise:</p>
            </div>
            <p className="leading-9">
              {arrDays[departure?.getDay()]}, {departure.getDate()}. {arrMonths[departure?.getMonth()]}{' '}
              {departure?.getFullYear()}
            </p>
          </div>
        </div>

        {(productDetails?.termsAndConditions?.touristTaxType === 0 ||
          productDetails?.termsAndConditions?.touristTaxType === 1) && (
          <div className="flex justify-center border-b border-booking-primaryBorder text-booking-tertiary">
            <div className="p-4">
              <p className="">Steuern und Gebühren:</p>
              <p className="leading-9">
                <span>Orts-/Kurtaxe:</span>
                <span className="ps-2">
                  {formatPrice(
                    (productDetails?.termsAndConditions?.touristTaxAmount || 15) * totalGuests,
                    productDetails?.currency,
                    'de',
                    2,
                    2,
                  )}{' '}
                  (
                  {formatPrice(
                    productDetails?.termsAndConditions?.touristTaxAmount || 15,
                    productDetails?.currency,
                    'de',
                    2,
                    2,
                  )}{' '}
                  Pro Person)
                </span>
              </p>
              <p>Steuern und Gebühren sind for Ort zu bezahlen</p>
            </div>
          </div>
        )}

        <div className="p-4">
          <p>
            <span className="text-booking-tertiary">Gesamt:</span>
            <span className="font-bold text-booking-primary ps-2">
              {formatPrice(
                totalPrice + (productDetails?.termsAndConditions?.touristTaxAmount || 15) * totalGuests,
                productDetails?.currency,
                'de',
                2,
                2,
              )}
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default BookingConfirmation

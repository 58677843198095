import React from 'react'
import { useNavigate } from 'react-router'
import currency_symbols from 'currency-symbol-map'
import { formatPrice } from '../../../utils/reusableFunctions'
// import Rating from './Rating'

const HotelCards = ({ products, rooms, adults, arrival, departure, nights, productDetails, setProductDetails }) => {
  const navigate = useNavigate()
  return (
    <div className="mx-auto mt-2.5">
      {products.map((value, index) => {
        const location = value.accommodation?.touristicDestinationType?.find(({ isoCode }) => isoCode === 'de')?.text
        const minimumStay =
          value.arrivalDepartureSlots
            ?.map((slot) => slot.minimumStay)
            ?.reduce((min, current) => Math.min(min, current), Infinity) || 1
        const minimumStayString = `${minimumStay} ${minimumStay === 1 ? ' Nacht' : ' Nächte'} p.P. ab` || 'ab'
        const stars = value.accommodation?.starCategory || 3
        const flights = value.specialServices?.filter((service) => service?.specialServiceType === 0)
        // Here's a naiive way to sort the flights to right order just by using sort() method.
        // It should work so that the airport information is passed to this function from the search so
        // we can use this information to sort the flights in the right order
        const flightsString =
          flights?.length > 0 &&
          `<span class="text-booking-tertiary opacity-[0.6]">${flights?.length === 1 ? 'Flug' : 'Flüge'}:</span><br>` +
            flights
              ?.map((flight) => flight?.name?.find(({ isoCode }) => isoCode === 'de')?.text)
              ?.sort()
              ?.join('<br>')
              ?.replaceAll(' - ', '-')
              ?.replaceAll('-', ' - ')
        // console.log({ location, minimumStay, flights, flightsString })
        if (index < 10) {
          return (
            <div className="py-2.5">
              <div className="flex w-full p-5 pr-0 border border-booking-primaryBorder rounded-booking-lg">
                <div className="w-[324px] h-auto">
                  <img
                    className="object-cover object-center w-full h-full border rounded-booking-lg"
                    src={value.images[0]?.url}
                    width="325"
                    height="215"
                    alt="room1"
                  />
                </div>
                <div className="w-[calc(100%_-_324px)] pl-7 pt-9 pb-4">
                  <div className="mb-14">
                    <h5 className="font-bold">{value.accommodation.name}</h5>
                    <div>
                      <div className="flex text-booking-lg leading-booking-3xl">
                        <span className="text-booking-tertiary opacity-[0.6]">{location}</span>
                        {value.accommodation.starCategory && (
                          <span className="flex">
                            {[...Array(5)].map((star, index) => (
                              <img
                                className="ml-2"
                                src={`%PUBLIC_URL%/img/${index + 1 <= value.accommodation.starCategory ? 'starfill.svg' : 'starunfill.svg'}`}
                                alt="star1"
                              />
                            ))}
                          </span>
                        )}
                        {/* <span className="flex">
                          <img
                            className="ml-2"
                            src={`%PUBLIC_URL%/img/${stars >= 1 ? 'starfill.svg' : 'starunfill.svg'}`}
                            alt="star1"
                          />
                          <img
                            className="ml-2"
                            src={`%PUBLIC_URL%/img/${stars >= 2 ? 'starfill.svg' : 'starunfill.svg'}`}
                            alt="star2"
                          />
                          <img
                            className="ml-2"
                            src={`%PUBLIC_URL%/img/${stars >= 3 ? 'starfill.svg' : 'starunfill.svg'}`}
                            alt="star3"
                          />
                          <img
                            className="ml-2"
                            src={`%PUBLIC_URL%/img/${stars >= 4 ? 'starfill.svg' : 'starunfill.svg'}`}
                            alt="star4"
                          />
                          <img
                            className="ml-2"
                            src={`%PUBLIC_URL%/img/${stars === 5 ? 'starfill.svg' : 'starunfill.svg'}`}
                            alt="star5"
                          />
                        </span> */}
                      </div>
                      <div>{/* <Rating props={value.accommodation?.starCategory} /> */}</div>
                    </div>
                    <p className="pt-4 text-booking-tertiary" dangerouslySetInnerHTML={{ __html: flightsString }}></p>
                  </div>
                  <div className="flex items-center justify-end gap-2 mt-auto">
                    <div className="text-booking-tertiary text-booking-lg leading-booking-3xl">
                      {minimumStayString}{' '}
                    </div>
                    <div className="relative items-center w-auto">
                      <div className="rounded-booking-lg absolute w-auto ml-4 mx-auto bottom-[80%] flex p-2 items-center justify-center bg-booking-tertiary-100">
                        <span className="px-2.5 border-r border-booking-primary">
                          <img
                            className="w-[24px]"
                            src="%PUBLIC_URL%/img/location.svg"
                            width="24"
                            height="24"
                            alt="location"
                          />
                        </span>
                        <span className="px-2.5">
                          <img
                            className="w-[20px]"
                            src="%PUBLIC_URL%/img/heart.svg"
                            width="24"
                            height="24"
                            alt="heart"
                          />
                        </span>
                      </div>
                      <button
                        onClick={() => {
                          console.log({ value })
                          setProductDetails(value)
                          navigate('/hotel')
                        }}
                        className="px-6 pt-5 pb-2 bg-booking-secondary text-booking-primary rounded-tl-booking-lg rounded-bl-booking-lg text-booking-3xl font-booking-heading"
                      >
                        {/* {value.pricePerAdult} {currency_symbols(value?.currency || 'EUR')} */}
                        {formatPrice(value.pricePerAdult, value?.currency, 'de', 2, 2)}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      })}
      <div className="flex items-center justify-end mt-4 text-booking-primary-100">
        <span className="px-2 text-booking-primary"> 1</span>|<span className="px-2"> 2</span>|
        <span className="px-2"> 3</span>|<span className="px-2"> 4</span> |<span className="px-2"> 5</span> -
        <span className="px-2"> 60</span>
        <button
          // onClick={() => navigate('/hotel')}
          className="text-booking-primary-300 bg-booking-primary p-2.5 rounded-booking-md mx-1"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path
              d="M8.47324 11.2031L8.95605 10.6992L4.05176 6L8.95605 1.30055L8.47324 0.796875L3.04324 6L8.47324 11.2031Z"
              fill="white"
            />
          </svg>
        </button>
        <button
          // onClick={() => navigate('/hotel')}
          className="text-booking-primary-300 bg-booking-primary p-2.5 rounded-booking-md mx-1"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path
              d="M3.52676 11.2031L3.04395 10.6992L7.94824 6L3.04395 1.30055L3.52676 0.796875L8.95676 6L3.52676 11.2031Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default HotelCards

import React from 'react'
import SummaryDetails from '../../SummaryDetails/v_1/SummeryDetails'
import Breadcrumbs from '../../Breadcrumbs/v_1/Breadcrumbs'
import Stepper from '../../Stepper/v_1/Stepper'
import PassengerInfo from '../../PassengerInfo/v_1/PassengerInfo'

const ContactForm = ({
  products,
  rooms,
  adults,
  arrival,
  departure,
  nights,
  productDetails,
  selectedRooms,
  setSelectedRooms,
  totalPrice,
  setTotalPrice,
  totalGuests,
  setTotalGuests,
  totalRooms,
  setTotalRooms,
}) => {
  return (
    <div className="mx-auto my-4 font-booking-primary">
      <Breadcrumbs />
      <div className="pt-10 text-booking-tertiary">
        <Stepper />
      </div>
      <div className="w-full gap-4 pt-12">
        <h2 className="font-bold text-booking-heading text-booking-4xl font-booking-secondary">Fast gebucht!</h2>
      </div>
      <div className="flex w-full">
        <div className="w-2/3">
          <PassengerInfo />
        </div>
        <div className="w-2/6 pl-5">
          <SummaryDetails
            products={products}
            rooms={rooms}
            adults={adults}
            arrival={arrival}
            departure={departure}
            nights={nights}
            productDetails={productDetails}
            selectedRooms={selectedRooms}
            setSelectedRooms={setSelectedRooms}
            totalPrice={totalPrice}
            setTotalPrice={setTotalPrice}
            totalGuests={totalGuests}
            setTotalGuests={setTotalGuests}
            totalRooms={totalRooms}
            setTotalRooms={setTotalRooms}
            showFlightDetails={true}
          />
        </div>
      </div>
    </div>
  )
}

export default ContactForm

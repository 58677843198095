import React from 'react'
import { useNavigate } from 'react-router'
const PassengerInfo = () => {
  const navigate = useNavigate()

  return (
    <div className="relative w-full">
      <form
        className="w-full"
        onSubmit={(e) => {
          e.preventDefault()
          navigate('/insuranceInfo')
        }}
      >
        <div className="mt-6 border border-booking-primaryBorder rounded-booking-lg">
          <h5 className="px-5 py-3.5 border-b border-booking-primaryBorder">Hauptreisender</h5>
          <div className="p-5">
            <div className="flex justify-between w-full gap-5">
              <span className="relative w-1/5">
                <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">
                Anrede
                </label>
                <select
                  name="gender"
                  className="w-full px-3 pt-6 pb-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                >
                  <option value=""></option>
                  <option value="M">Herr</option>
                  <option value="F">Frau</option>
                </select>
              </span>
              <span className="relative w-2/5">
                <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">Vorname*</label>
                <input
                  type="text"
                  name="firstname"
                  className="w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                />
              </span>
              <span className="relative w-2/5">
                <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">Nachname*</label>
                <input
                  type="text"
                  name="lastname"
                  className="w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                />
              </span>
            </div>
            <div className="flex gap-5 pt-6">
              <span className="relative w-1/2">
                <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">E-Mail*</label>
                <input
                  type="text"
                  name="email"
                  className="w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                />
              </span>
              <span className="relative w-1/2">
                <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">
                  Telefonnummer*
                </label>
                <input
                  type="text"
                  name="telephone"
                  className="w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                />
              </span>
            </div>
            <div className="flex gap-5 pt-6">
              <span className="relative w-full">
                <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">
                  Geburtsdatum
                </label>
                <input
                  type="date"
                  name="geburtsdatum"
                  className="w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg datePlaceholder"
                  lang='ge'
                />
              </span>
            </div>
          </div>
        </div>
        <div className="mt-6 border border-booking-primaryBorder rounded-booking-lg">
          <h5 className="px-5 py-3.5 border-b border-booking-primaryBorder">Reisender 2</h5>
          <div className="p-5">
            <div className="flex justify-between w-full gap-5">
              <span className="relative w-1/5">
                <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">
                Anrede
                </label>
                <select
                  name="gender"
                  className="w-full px-3 pt-6 pb-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                >
                  <option value=""></option>
                  <option value="M">Herr</option>
                  <option value="F">Frau</option>
                </select>
              </span>
              <span className="relative w-2/5">
                <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">Vorname*</label>
                <input
                  type="text"
                  name="firstname"
                  className="w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                />
              </span>
              <span className="relative w-2/5">
                <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">Nachname*</label>
                <input
                  type="text"
                  name="lastname"
                  className="w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                />
              </span>
            </div>
            <div className="flex gap-5 pt-6">
              <span className="relative w-full">
                <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">
                  Geburtsdatum
                </label>
                <input
                  type="date"
                  name="geburtsdatum"
                  className="w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                />
              </span>
            </div>
            <div className="relative flex items-end justify-end w-full my-6"></div>
          </div>
        </div>
        <div className="mt-6 border border-booking-primaryBorder rounded-booking-lg">
          <h5 className="px-5 py-3.5 border-b border-booking-primaryBorder">Reisender 3</h5>
          <div className="p-5">
            <div className="flex justify-between w-full gap-5">
              <span className="relative w-1/5">
                <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">
                Anrede
                </label>
                <select
                  name="gender"
                  className="w-full px-3 pt-6 pb-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                >
                  <option value=""></option>
                  <option value="M">Herr</option>
                  <option value="F">Frau</option>
                </select>
              </span>
              <span className="relative w-2/5">
                <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">Vorname*</label>
                <input
                  type="text"
                  name="firstname"
                  className="w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                />
              </span>
              <span className="relative w-2/5">
                <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">Nachname*</label>
                <input
                  type="text"
                  name="lastname"
                  className="w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                />
              </span>
            </div>
            <div className="flex gap-5 pt-6">
              <span className="relative w-full">
                <label className="absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5">
                  Geburtsdatum
                </label>
                <input
                  type="date"
                  name="geburtsdatum"
                  className="w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg"
                />
              </span>
            </div>
          </div>
        </div>
        <div className="relative flex items-end justify-end w-full my-6">
          <button
            type="submit"
            className="right-0 px-6 pt-4 pb-3 mt-4 font-bold leading-none rounded-full text-booking-primary bg-booking-secondary font-booking-heading"
            style={{ backgroundColor: '#FFB700' }}
          >
            Nächste
          </button>
        </div>
      </form>
      {/* <div className='relative flex items-end justify-end w-full my-6'>
        <button type='submit' onClick={() => navigate("/insuranceInfo")} className='right-0 px-6 pt-4 pb-3 mt-4 font-bold leading-none rounded-full text-booking-primary bg-booking-secondary font-booking-heading'>Next</button>
      </div> */}
    </div>
  )
}

export default PassengerInfo

import currency_symbols from 'currency-symbol-map'

// Night Find beetween checkindate and checkoutdate
export function daysDiff(startDate: Date, endDate: Date) {
  let night = Math.round((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24))
  return night
}

export const dateLocales: { [key: string]: string } = {
  en: 'en-EN',
  de: 'de-DE',
  it: 'it-IT',
  fr: 'fr-FR',
  nl: 'nl-NL',
}

export const formatDate = (date: Date, language: string) => {
  return `${date.toLocaleDateString(dateLocales[language === 'de' ? 'de' : 'it'])}`
}

export const formatPrice = (
  price: number,
  currencyCode: string,
  language: string,
  priceMinFraction: number,
  priceMaxFraction: number,
) => {
  return language == 'de'
    ? `${price.toLocaleString(`de-DE`, {
        minimumFractionDigits: priceMinFraction,
        maximumFractionDigits: priceMaxFraction,
      })} ${currency_symbols(currencyCode || 'EUR')}`
    : `${currency_symbols(currencyCode || 'EUR')} ${price.toLocaleString(`de-DE`, {
        minimumFractionDigits: priceMinFraction,
        maximumFractionDigits: priceMaxFraction,
      })}`
}

import { FilterCards, HotelCards, HotelFilters } from '../../../components'
import React, { useEffect } from 'react'

function Home({
  products,
  rooms,
  adults,
  arrival,
  departure,
  nights,
  productDetails,
  selectedRooms,
  setSelectedRooms,
  totalPrice,
  setTotalPrice,
  totalGuests,
  setTotalGuests,
  totalRooms,
  setTotalRooms,
  setProductDetails,
}) {
  useEffect(() => {
    setSelectedRooms({
      rooms: {},
      totalPrice: 0,
    })
    setTotalPrice(0)
    setTotalGuests(0)
    setTotalRooms(0)
    setProductDetails({})
  },[])

  return (
    <div className="flex px-4 mx-auto my-4 max-w-7xl lg:px-8 xl:px-14">
      <div className="w-[297px]">
        <HotelFilters />
      </div>
      <div className="w-[calc(100%_-_297px)]">
        <FilterCards />
        <HotelCards
          products={products}
          rooms={rooms}
          adults={adults}
          arrival={arrival}
          departure={departure}
          nights={nights}
          productDetails={productDetails}
          setProductDetails={setProductDetails}
        />
      </div>
    </div>
  )
}

export default Home

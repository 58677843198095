import React from 'react'

export default function FilterCheckbox({props,name=''}) {
  return (
      <div>
      {
        props.map((value,index) => {
          return  <div key={index} className="flex custom-checkbox">
        {" "}
        <input id={name+'_'+index} type="checkbox" />
        <span class="checkmark"></span>
        <label for={name + '_' + index} className="text-booking-tertiary cursor-pointer">{value}</label>
          </div>
        })
      }
    </div>
  );
}

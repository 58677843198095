import React from 'react'

const FilterCards = () => {
  return (
    <div className="mx-auto">
      <section className="flex justify-center w-full py-5 border border-booking-primaryBorder rounded-booking-lg">
        <div className='flex items-center '>
          <h6 className='pr-14'>
            600 Angebote
          </h6>
          <div className='w-auto px-2.5'>
            <div className='bg-booking-primary text-booking-primary-300 py-3.5 rounded-booking-lg flex items-center pl-6 pr-3'>
              <p>Sortierung nach: </p>
              <select className='pl-3 pr-6 outline-none bg-booking-primary text-booking-primary-300'>
                <option value='1'>Beliebteste</option>
                <option value='2'>letzte</option>
                <option value='3'>Neue Stadt</option>
              </select>
            </div>
          </div>
          <div className='w-auto px-2.5'>
            <div className='bg-booking-primary text-booking-primary-300  px-5 py-3.5 rounded-booking-lg flex items-center'>
              <p className='text-booking-lg'>Pro Seite: </p>
              <select className='pl-3 pr-6 outline-none bg-booking-primary text-booking-primary-300'>
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='3'>3</option>
                <option value='4'>4</option>
                <option value='5'>5</option>
                <option value='6'>6</option>
                <option value='7'>7</option>
                <option value='8'>8</option>
                <option value='9'>9</option>
                <option value='10'>10</option>
              </select>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default FilterCards

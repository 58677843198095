import React from 'react'
import { useNavigate } from 'react-router'
import { formatPrice } from '../../../utils/reusableFunctions'

const SummaryDetails = ({
  products,
  rooms,
  adults,
  arrival,
  departure,
  nights,
  productDetails,
  selectedRooms,
  setSelectedRooms,
  totalPrice,
  setTotalPrice,
  totalGuests,
  setTotalGuests,
  totalRooms,
  setTotalRooms,
  showBookNow = false,
  showFlightDetails=false
}) => {
  const navigate = useNavigate()
  const DaysString = {
    de: 'Sonntag,Montag,Dienstag,Mittwoch,Donnerstag,Freitag,Samstag',
  }
  const arrDays = DaysString['de'].split(',')

  const MonthsString = {
    de: 'Januar,Februar,März,April,Mai,Juni,Juli,August,September,Oktober,November,Dezember',
  }
  const arrMonths = MonthsString['de'].split(',')

  const flights = productDetails.specialServices?.filter((service) => service?.specialServiceType === 0)
  const flightsString =
    flights?.length > 0 &&
      flights
        ?.map((flight) => flight?.description?.find(({ isoCode }) => isoCode === 'de')?.text)
        ?.sort()
        ?.join('<br>')
        ?.replaceAll(' - ', '-')
        ?.replaceAll('-', ' - ')


  return (
    <div className="border border-booking-primaryBorder rounded-booking-md h-max bg-[#F5F7FA]">
      <h6 className="p-5">Buchungszusammenfassung</h6>

      <div className="flex justify-between px-5 py-3 mt-3 MTS-w-full">
        <span className="font-bold text-booking-primary font-booking-primary text-booking-lg">
          {productDetails?.accommodation?.name}
        </span>
        <span
          className="font-normal cursor-pointer text-booking-primary font-booking-primary text-booking-lg hover:underline"
          onClick={() => navigate('/')}
        >
          Ändern
        </span>
      </div>

      <div className="w-full p-0 my-3 border-b border-booking-primaryBorder"></div>
      <div className="px-5 py-3">
        {Object?.keys(selectedRooms).map((selectedRoom) => {
          const rooms = selectedRooms[selectedRoom]
          return Object.keys(rooms).map((board) => {
            if (rooms?.[board]?.guests > 0 && rooms?.[board]?.rooms > 0) {
              return (
                <>
                  <span
                    key={selectedRoom + board}
                    className="text-booking-tertiary font-booking-primary text-booking-lg opacity-80"
                  >
                    {rooms?.[board]?.rooms} Zimmer für {nights} Nächte : {rooms?.[board]?.guests} Erwachsene
                  </span>
                  <br />
                </>
              )
            } else {
              return null
            }
          })
        })}
        {/* <br />
              <span className="text-booking-tertiary font-booking-primary text-booking-lg opacity-80">
                1 Zimmer für 6 Nächte : 2 Erwachsene
              </span> */}

        <div className="flex justify-between mt-3 MTS-w-full">
          <div>
            <h6>Aufenthaltsdauer</h6>
            <div className="mt-2 font-normal text-booking-tertiary font-booking-primary text-booking-lg opacity-80">
              {arrDays[arrival?.getDay()]}, {arrival.getDate()}. {arrMonths[arrival?.getMonth()]}{' '}
              {arrival?.getFullYear()} bis
            </div>
            <div className="font-normal text-booking-tertiary font-booking-primary text-booking-lg opacity-80">
              {arrDays[departure?.getDay()]}, {departure.getDate()}. {arrMonths[departure?.getMonth()]}{' '}
              {departure?.getFullYear()}
            </div>
          </div>
          <div
            className="font-normal cursor-pointer text-booking-primary font-booking-primary text-booking-lg hover:underline"
            onClick={() => navigate('/')}
          >
            Ändern
          </div>
        </div>
      </div>
      {/* <div className="w-full p-0 my-3 border-b border-booking-primaryBorder"></div> */}
      {/* <div className="px-5 py-3">
        <span className="font-normal font-booking-secondary text-booking-xl text-booking-primary">
          Steuern und Gebühren
        </span>

        <div className="mt-3">
          <div className="flex justify-between">
            <span className="font-normal text-booking-tertiary text-booking-lg font-booking-primary">
              Orts-/Kurtaxe
            </span>
            <span className="font-normal text-booking-tertiary text-booking-lg font-booking-primary">
              {formatPrice(
                (productDetails?.termsAndConditions?.touristTaxAmount || 15) * totalGuests,
                productDetails?.currency,
                'de',
                2,
                2,
              )}
            </span>
          </div>
          <div>
            <span className="font-normal text-booking-tertiary text-booking-lg font-booking-primary">
              {formatPrice(
                productDetails?.termsAndConditions?.touristTaxAmount || 15,
                productDetails?.currency,
                'de',
                2,
                2,
              )}{' '}
              Pro Person
            </span>
          </div>
        </div>
      </div> */}
      <div className="w-full p-0 my-3 border-b border-booking-primaryBorder"></div>

      {showFlightDetails && (
        <>
          <div className="px-5 py-3">
            <div className="flex justify-between">
              <h6>Reisedetails</h6>
              <div
                className="font-normal cursor-pointer text-booking-primary font-booking-primary text-booking-lg hover:underline"
                onClick={() => navigate('/hotel')}
              >
                Ändern
              </div>
            </div>
            <div className="block text-booking-base text-booking-tertiary">
              {/* {productDetails?.specialServices?.map((service) => (
                <div
                  dangerouslySetInnerHTML={{
                    __html: service?.description?.find((desc) => desc.isoCode === 'de')?.text || '',
                  }}
                ></div>
              ))} */}
                <p dangerouslySetInnerHTML={{ __html: flightsString }}></p>
            </div>
          </div>
          <div className="w-full p-0 my-3 border-b border-booking-primaryBorder"></div>
        </>
      )}

      <div className="px-5 py-3">
        <div className="flex justify-between">
          <div>
            <span className="font-normal text-booking-primary font-booking-secondary text-booking-2xl">Gesamt</span>
          </div>
          <div>
            <div className="font-normal text-booking-primary font-booking-secondary text-booking-3xl">
              {/* 1.605,00 € */}
              {formatPrice(
                totalPrice,
                productDetails?.currency,
                'de',
                2,
                2,
              )}
            </div>
            {showBookNow && (
              <div className="relative flex items-end justify-end w-full">
                <button
                  onClick={() => navigate('/contactForm')}
                  className="right-0 px-6 pt-4 pb-3 mt-4 font-bold leading-none rounded-full text-booking-primary bg-booking-secondary font-booking-heading"
                >
                  Jetzt buchen
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SummaryDetails

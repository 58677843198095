import React from 'react'
import { BookingConfirmation, Breadcrumbs, Stepper } from '../../../components'

function BookingConfirm({
  products,
  rooms,
  adults,
  arrival,
  departure,
  nights,
  productDetails,
  selectedRooms,
  setSelectedRooms,
  totalPrice,
  setTotalPrice,
  totalGuests,
  setTotalGuests,
  totalRooms,
  setTotalRooms,
}) {
  return (
    <div className="w-2/3 mx-auto my-4 font-booking-primary">
      <Breadcrumbs />
      <div className="pt-10 text-booking-booking-tertiary">
        <Stepper stepper="success" />
      </div>
      <div className="flex my-4">
        <BookingConfirmation
          products={products}
          rooms={rooms}
          adults={adults}
          arrival={arrival}
          departure={departure}
          nights={nights}
          productDetails={productDetails}
          selectedRooms={selectedRooms}
          setSelectedRooms={setSelectedRooms}
          totalPrice={totalPrice}
          setTotalPrice={setTotalPrice}
          totalGuests={totalGuests}
          setTotalGuests={setTotalGuests}
          totalRooms={totalRooms}
          setTotalRooms={setTotalRooms}
        />
      </div>
    </div>
  )
}

export default BookingConfirm

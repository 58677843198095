import React, { useEffect, useRef, useState } from 'react'
import { Home, HotelInfo, ContactInfo, InsuranceInfo, BookingConfirm, PaymentDetails } from './pages'
import { MemoryRouter, Routes, Route } from 'react-router-dom'
import { ScrollToTop } from './components'
import { daysDiff } from './utils/reusableFunctions'
import products from './json/product.json'
import rooms from './json/rooms.json'

const App = () => {
  // BOOKING BAR WIDGET ATTRIBUTES
  const language = 'de'
  const clientId = 'u1022'
  const producName = 'booking-bar'
  const productVersion = '1'

  const bookingRef = useRef(null) // FOR BOOKING BAR WIDGET
  const [showBookingEngine, setShowBookingEngine] = useState(false) // SHOW/HIDE BOOKING ENGINE

  useEffect(() => {
    // APPEND SCRIPT TO LOAD BOOKING BAR WIDGET
    if (bookingRef?.current) {
      const script = document.createElement('script')

      script.src = 'https://cdn.mts-online.com/mts.js'
      script.async = true

      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
  }, [bookingRef?.current])

  useEffect(() => {
    // LOAD BOOKING BAR WIDGET IF GETTING URL PARAM - "booking-engine"
    const params = new URLSearchParams(window.location.search)
    const bookingEngine = params.get('booking-engine')
    setShowBookingEngine(bookingEngine === 'true' ? true : false)
  }, [])

  // get params values from URL
  const params = new URLSearchParams(window.location.search)
  const adultsCountParam = params.get('adultsCount')
  const childrenCountParam = params.get('childrenCount')

  // const productCode = `PK100007`
  // const [productCode, setProductCode] = useState(`PK100007`)
  const adults = adultsCountParam ? parseInt(adultsCountParam) : 2
  const arrivalParam = params.get('arrival')
  const departureParam = params.get('departure')
  const arrival = arrivalParam ? new Date(arrivalParam) : new Date('2023-11-20T18:30:00.000Z')
  const departure = departureParam ? new Date(departureParam) : new Date('2023-11-23T18:30:00.000Z')
  const nights = arrival && departure ? daysDiff(arrival, departure) : 7
  // const productDetails = products.find((product) => product?.productCode === productCode)
  const [productDetails, setProductDetails] = useState({})

  // For Summary
  const [selectedRooms, setSelectedRooms] = useState({
    rooms: {},
    totalPrice: 0,
  })
  const [totalPrice, setTotalPrice] = useState(0)
  const [totalGuests, setTotalGuests] = useState(0)
  const [totalRooms, setTotalRooms] = useState(0)

  return (
    <>
      {/* INTEGRATION CODE OF BOOKING BAR WIDGET FROM CL - START */}
      <div className="mx-auto skyalps-searchbar">
        <div
          id="__MTS"
          data-mts-language={language}
          data-mts-user={clientId}
          data-mts-view={producName}
          data-mts-version={productVersion}
          ref={bookingRef}
        ></div>
      </div>
      {/* INTEGRATION CODE OF BOOKING BAR WIDGET FROM CL - END */}

      {/* BOOKING ENGINE - START */}
      {bookingRef?.current && showBookingEngine && (
        <MemoryRouter>
          <ScrollToTop />
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  products={products}
                  rooms={rooms}
                  adults={adults}
                  arrival={arrival}
                  departure={departure}
                  nights={nights}
                  productDetails={productDetails}
                  selectedRooms={selectedRooms}
                  setSelectedRooms={setSelectedRooms}
                  totalPrice={totalPrice}
                  setTotalPrice={setTotalPrice}
                  totalGuests={totalGuests}
                  setTotalGuests={setTotalGuests}
                  totalRooms={totalRooms}
                  setTotalRooms={setTotalRooms}
                  setProductDetails={setProductDetails}
                />
              }
            />
            <Route
              path="/hotel"
              element={
                <HotelInfo
                  products={products}
                  rooms={rooms}
                  adults={adults}
                  arrival={arrival}
                  departure={departure}
                  nights={nights}
                  productDetails={productDetails}
                  selectedRooms={selectedRooms}
                  setSelectedRooms={setSelectedRooms}
                  totalPrice={totalPrice}
                  setTotalPrice={setTotalPrice}
                  totalGuests={totalGuests}
                  setTotalGuests={setTotalGuests}
                  totalRooms={totalRooms}
                  setTotalRooms={setTotalRooms}
                />
              }
            ></Route>
            <Route
              path="/contactForm"
              element={
                <ContactInfo
                  products={products}
                  rooms={rooms}
                  adults={adults}
                  arrival={arrival}
                  departure={departure}
                  nights={nights}
                  productDetails={productDetails}
                  selectedRooms={selectedRooms}
                  setSelectedRooms={setSelectedRooms}
                  totalPrice={totalPrice}
                  setTotalPrice={setTotalPrice}
                  totalGuests={totalGuests}
                  setTotalGuests={setTotalGuests}
                  totalRooms={totalRooms}
                  setTotalRooms={setTotalRooms}
                />
              }
            ></Route>
            <Route
              path="/insuranceInfo"
              element={
                <InsuranceInfo
                  products={products}
                  rooms={rooms}
                  adults={adults}
                  arrival={arrival}
                  departure={departure}
                  nights={nights}
                  productDetails={productDetails}
                  selectedRooms={selectedRooms}
                  setSelectedRooms={setSelectedRooms}
                  totalPrice={totalPrice}
                  setTotalPrice={setTotalPrice}
                  totalGuests={totalGuests}
                  setTotalGuests={setTotalGuests}
                  totalRooms={totalRooms}
                  setTotalRooms={setTotalRooms}
                />
              }
            ></Route>
            <Route
              path="/paymentdetails"
              element={
                <PaymentDetails
                  products={products}
                  rooms={rooms}
                  adults={adults}
                  arrival={arrival}
                  departure={departure}
                  nights={nights}
                  productDetails={productDetails}
                  selectedRooms={selectedRooms}
                  setSelectedRooms={setSelectedRooms}
                  totalPrice={totalPrice}
                  setTotalPrice={setTotalPrice}
                  totalGuests={totalGuests}
                  setTotalGuests={setTotalGuests}
                  totalRooms={totalRooms}
                  setTotalRooms={setTotalRooms}
                />
              }
            ></Route>
            <Route
              path="/bookingsuccess"
              element={
                <BookingConfirm
                  products={products}
                  rooms={rooms}
                  adults={adults}
                  arrival={arrival}
                  departure={departure}
                  nights={nights}
                  productDetails={productDetails}
                  selectedRooms={selectedRooms}
                  setSelectedRooms={setSelectedRooms}
                  totalPrice={totalPrice}
                  setTotalPrice={setTotalPrice}
                  totalGuests={totalGuests}
                  setTotalGuests={setTotalGuests}
                  totalRooms={totalRooms}
                  setTotalRooms={setTotalRooms}
                />
              }
            ></Route>
          </Routes>
        </MemoryRouter>
      )}
      {/* BOOKING ENGINE - END */}
    </>
  )
}

export default App

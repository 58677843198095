import { HotelDetailCards } from '../../../components'
import React from 'react'

function HotelInfo({
  products,
  rooms,
  adults,
  arrival,
  departure,
  nights,
  productDetails,
  selectedRooms,
  setSelectedRooms,
  totalPrice,
  setTotalPrice,
  totalGuests,
  setTotalGuests,
  totalRooms,
  setTotalRooms,
}) {
  return (
    <div className="flex px-4 mx-auto my-4 max-w-7xl lg:px-8 xl:px-14">
      <div className="w-full">
        <HotelDetailCards
          products={products}
          rooms={rooms}
          adults={adults}
          arrival={arrival}
          departure={departure}
          nights={nights}
          productDetails={productDetails}
          selectedRooms={selectedRooms}
          setSelectedRooms={setSelectedRooms}
          totalPrice={totalPrice}
          setTotalPrice={setTotalPrice}
          totalGuests={totalGuests}
          setTotalGuests={setTotalGuests}
          totalRooms={totalRooms}
          setTotalRooms={setTotalRooms}
        />
      </div>
    </div>
  )
}

export default HotelInfo

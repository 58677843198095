import React, { useState } from 'react'

const Paymentinfo = () => {

  const [showInput, setShowInput] = useState(false)

  const handleInputFields = (e: boolean) => {
    setShowInput(e)
  }
  return (
    <div>
      {/* title div */}
      <div className='p-5 font-normal text-booking-primary font-booking-secondary text-booking-5xl'>Fast gebucht!</div>

      {/* check box div */}
      <div className='flex px-5 mt-3 custom-checkbox'>
        <input type="checkbox" name="fillForm" id="fillForm" className='border border-booking-primary accent-primary' onChange={(e) => handleInputFields(e.target.checked)} />
        <span className="checkmark"></span>
        <label className='cursor-pointer text-booking-primary' htmlFor='fillForm'>Firmenrechnung</label>
        </div>

      {/* form div */}
      <div className='pb-3 my-8 border border-booking-primaryBorder rounded-booking-lg'>
        {showInput === true ? <div className='p-3 font-normal text-booking-primary font-booking-secondary text-booking-2xl'>Firmenrechnung</div> : <div className='p-3 font-normal text-booking-primary font-booking-secondary text-booking-2xl'>Zahlungs Information</div>}

        <div className='border-b border-booking-primary-400'></div>
        <div className='p-5'>
          {/* part 1 */}
          <div>
            {showInput === false ? <div className='flex gap-5 pt-5'>
              <div className='relative w-1/5'>
                <label className='absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5'>Anrede</label>
                  <select name="Anrede" id="Anrede" className='w-full px-3 pt-6 pb-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg'>
                    <option value="def"></option>
                    <option value="M">Herr</option>
                    <option value="F">Frau</option>
                  </select>
              </div>

              <div className='relative w-2/5'>
                <span className='absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5'>Vorname<span className='text-booking-primary-600'>*</span></span>
                <div><input type="text" name="Vorname" id="Vorname" className='w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg' /></div>
              </div>

              <div className='relative w-2/5'>
                <span className='absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5'>Nachname<span className='text-booking-primary-600'>*</span></span>
                <div><input type="text" name="Nachname" id="Nachname" className='w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg' /></div>
              </div>
            </div> : ''}
            <div className='flex gap-5 pt-5'>
              {/* First input */}
              <div className='relative w-1/2'>
                <span className='absolute z-10 top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5'>Land auswählen</span>
                <div className='w-full'>
                  <select name="country" id="country" className='w-full px-3 pt-6 pb-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg'>
                    <option value="def">Land auswählen</option>
                    <option value="India">India</option>
                    <option value="Canada">Canada</option>
                    <option value="Australia">Australia</option>
                    <option value="Germany">Germany</option>
                    <option value="Italy">Italy</option>
                  </select>
                </div>
              </div>

              {/* Second input */}
              {showInput === true ?
                <div className='relative w-1/2'>
                  <span className='absolute z-10 top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5'>Name der Firma</span>
                  <div><input type="text" name="userName" id="userName" className='w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg' placeholder='Name' /></div>
                </div>
                :
                <div className='relative w-1/2'>
                  <span className='absolute top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5'>Italian tax number</span>
                  <div><input type="text" name="userName" id="userName" className='w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg' placeholder='Italian tax number' /></div>
                </div>
              }
            </div>
          </div>

          {/* Part 2 */}
          {showInput === true ? <div className='flex gap-5 pt-5'>
            <div className='relative w-1/2'>
              <span className='absolute z-10 top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5'>MwSt.-Nr</span>
              <div><input type="text" name="MwSt" id="MwSt" className='w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg' placeholder='MwSt.-Nr' /></div>
            </div>

            <div className='relative w-1/2'>
              <span className='absolute z-10 top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5'>Name der Firma</span>
              <div><input type="text" name="Name_der_Firma" id="Name_der_Firma" className='w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg' placeholder='Name' /></div>
            </div>
          </div> : ''}

          {/* Part 3 */}
          {showInput === true ? <div className='flex gap-5 pt-5'>
            <div className='relative w-1/2'>
              <span className='absolute z-10 top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5'>Straße<span className='text-booking-primary-600'>*</span></span>
              <div className='w-full'>
                <select name="street" id="street" className='w-full px-3 pt-6 pb-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg'>
                  <option value="def">Select</option>
                  <option value="Aaland-Inseln">Aaland-Inseln</option>
                  <option value="Aaland-Inseln">Aaland-Inseln</option>
                  <option value="Aaland-Inseln">Aaland-Inseln</option>
                </select>
              </div>
            </div>

            <div className='relative w-1/2'>
              <span className='absolute z-10 top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5'>PLZ</span>
              <div><input type="text" name="PLZ" id="PLZ" className='w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg' /></div>
            </div>
          </div> : ''}

          {/* Part 4 */}
          {showInput === true ? <div className='flex gap-5 pt-5'>
            <div className='relative w-1/2'>
              <span className='absolute z-10 top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5'>Ort</span>
              <div><input type="text" name="Ort" id="Ort" className='w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg' /></div>
            </div>

            <div className='relative w-1/2'>
              <span className='absolute z-10 top-0 left-4 -translate-y-1/2 inline-block bg-white px-1.5'>Staat</span>
              <div><input type="text" name="Staat" id="Staat" className='w-full px-3 py-5 bg-transparent border outline-none border-booking-primaryBorder rounded-booking-lg' /></div>
            </div>
          </div> : ''}
        </div>
      </div>
    </div>

  )
}

export default Paymentinfo

import React from 'react'
import { Breadcrumbs, Cardinfo, Paymentinfo, Stepper, SummaryDetails } from '../../../components'

function PaymentDetails({
  products,
  rooms,
  adults,
  arrival,
  departure,
  nights,
  productDetails,
  selectedRooms,
  setSelectedRooms,
  totalPrice,
  setTotalPrice,
  totalGuests,
  setTotalGuests,
  totalRooms,
  setTotalRooms,
}) {
  return (
    <div className="w-2/3 mx-auto my-4">
      <Breadcrumbs />
      <div className="pt-10 text-booking-booking-tertiary">
        <Stepper />
      </div>
      <div className="flex w-full">
        <div className="w-2/3 mt-5">
          <div>
            <Paymentinfo />
            <Cardinfo />
          </div>
        </div>
        <div className="w-2/6 pl-5 mt-36">
          <SummaryDetails
            products={products}
            rooms={rooms}
            adults={adults}
            arrival={arrival}
            departure={departure}
            nights={nights}
            productDetails={productDetails}
            selectedRooms={selectedRooms}
            setSelectedRooms={setSelectedRooms}
            totalPrice={totalPrice}
            setTotalPrice={setTotalPrice}
            totalGuests={totalGuests}
            setTotalGuests={setTotalGuests}
            totalRooms={totalRooms}
            setTotalRooms={setTotalRooms}
            showFlightDetails={true}
          />
        </div>
      </div>
    </div>
  )
}

export default PaymentDetails

import React from 'react'
import { useNavigate } from "react-router";

const Breadcrumbs = () => {
  const navigate = useNavigate()
  return (
    <div className="mb-12">
      <span className="text-booking-tertiary cursor-pointer">
        <span className='hover:underline' onClick={() => navigate('/')}>Startseite</span>
        <span className="px-2 hover:underline"> &#62;</span>
        {/* <a className='hover:underline' onClick={() => navigate(navigation)}>Cala Ganone</a>{" "}
        <span className="px-2 hover:underline"> &#62;</span> */}
        <a className='hover:underline' onClick={() => navigate('/hotel')}>Hoteldetails</a>{" "}
        <span className="px-2 hover:underline"> &#62;</span>
        <a className='hover:underline' onClick={() => navigate('/contactForm')}>Extras</a>{" "}
        <span className="px-2 hover:underline"> &#62;</span>
        <span className="text-booking-primary font-bold">
          Zahlungsdetails
        </span>
      </span>
    </div>
  )
}

export default Breadcrumbs

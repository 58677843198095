import React from 'react'

const Stepper = (blockProps:any) => {
  const {stepper}:any = blockProps || {}
  return (
    <div className='flex w-full justify-between relative'>
      <div className='absolute top-4 left-1/2 -translate-x-1/2 h-0.5 w-[calc(100%_-_160px)] bg-booking-primary'>
        <div className=''></div>
      </div>
      <div className='text-center w-[130px] items-center z-10 relative'>
        <div className='block'>
          <img src='%PUBLIC_URL%/img/icon/checkmark.svg' width='40' height='40' alt='icon' className='items-center mx-auto mb-3 bg-white' />
          <p>Zimmerauswahl</p>
        </div>
      </div>
      <div className='text-center w-[130px] items-center z-10 relative'>
        <div className='block'>
          <img src={stepper && stepper === 'success' ? '%PUBLIC_URL%/img/icon/checkmark.svg' : '%PUBLIC_URL%/img/icon/roundIconFilled.svg'} width='40' height='40' alt='icon' className='items-center mx-auto mb-3 bg-white' />
          <p>Buchungsdetails</p>
        </div>
      </div>
      <div className='text-center w-[130px] items-center z-10 relative'>
        <div className='block'>
          <img src={stepper && stepper === 'success' ? '%PUBLIC_URL%/img/icon/checkmark.svg' : '%PUBLIC_URL%/img/icon/roundIcon.svg'} width='40' height='40' alt='icon' className='items-center mx-auto mb-3 bg-white' />
          <p>Buchungsbestätigung</p>
        </div>
      </div>
    </div>
  )
}

export default Stepper
